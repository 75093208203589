<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form @submit.prevent="changeCurrentSession">
          <v-card>
            <v-card-title>Change current session</v-card-title>

            <v-card-text>
              <v-select
                v-model="form.currentSession"
                :items="sessionOptions"
                label="Current session"
                required
              ></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn type="submit" color="primary">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      form: {
        currentSession: null,
      },
    };
  },

  async mounted() {},

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["me"]),
    sessionOptions() {
      if (!this.event?.sessions) return [];

      const sessions = [{ name: "None", id: null }, ...this.event.sessions];

      return sessions.map((session) => ({
        text: session.name,
        value: session.id,
      }));
    },
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;
      const url = `${process.env.VUE_APP_API_URL}event/change_state`;

      const data = {
        ...this.form,
        event_id: 1,
      };

      await axios.post(url, data);
      this.isLoading = false;
    },

    async changeCurrentSession() {
      this.isLoading = true;

      const url = `${process.env.VUE_APP_API_URL}event/${this.event.id}/change_current_session`;
      const data = {
        session_id: this.form.currentSession,
      };

      await axios.post(url, data);

      this.isLoading = false;
    },
  },

  watch: {
    event: {
      immediate: true,
      deep: true,
      handler(event) {
        if (event) {
          this.form = { currentSession: event?.current_session?.id || null };
        }
      },
    },
  },
};
</script>

<style></style>